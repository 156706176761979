import { createContext, useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import {
    collection,
    doc,
    setDoc,
    getDoc,
    getDocs,
    getFirestore,
    query,
    where,
} from 'firebase/firestore';
import {
    getAuth,
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
    signOut,
} from 'firebase/auth';
import Pokedex from 'pokedex-promise-v2';
import helperFunctions from '../helpers/helper';
const {
    uniqueNamesGenerator,
    adjectives,
    animals,
} = require('unique-names-generator');
// SVG pokemon picture https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/35.svg limit is 649

const MatchManiaContext = createContext();

const firebaseConfig = {
    apiKey: 'AIzaSyBRVEZITizv1nxtUfiuRTjEXrEVojpOa5c',
    authDomain: 'matchmania.io',
    projectId: 'matchmania-b7201',
    storageBucket: 'matchmania-b7201.appspot.com',
    messagingSenderId: '1019339636021',
    appId: '1:1019339636021:web:424630a737138f218a207c',
};

const gameDifficulty = {
    hard: 68,
    medium: 34,
    easy: 18,
};

const pokedex = new Pokedex();
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export const MatchManiaContextProvider = (props) => {
    const [isScoreModalVisible, setIsScoreModalVisible] = useState(false);
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
    const [user, setUser] = useState({ id: null, username: null });
    const [gameSettings, setGameSettings] = useState({
        isGameOver: true,
        difficulty: 'easy',
        listOfPokemons: [],
    });
    const [recentScores, setRecentScores] = useState({
        time: 0,
        difficulty: '',
    });

    const showLoginModalHandler = () => {
        setIsLoginModalVisible(true);
    };

    const hideLoginModalHandler = () => {
        setIsLoginModalVisible(false);
    };

    const showScoreboardHandler = () => {
        setIsScoreboardVisible(true);
    };

    const hideScorebardHandler = () => {
        setIsScoreboardVisible(false);
    };

    const onStartGameHandler = (difficulty) => {
        setRecentScores({ time: 0, difficulty: difficulty });
        const interval = {
            limit: 100,
            offset: helperFunctions.randomIntFromInterval(1, 549),
        };
        pokedex.getPokemonsList(interval).then((response) => {
            let randomPokemons = helperFunctions
                .shuffleArray(response.results)
                .slice(0, gameDifficulty[difficulty] / 2);
            setGameSettings((prevState) => ({
                ...prevState,
                listOfPokemons: helperFunctions.shuffleArray([
                    ...randomPokemons,
                    ...randomPokemons,
                ]),
            }));
        });
        setGameSettings((prevState) => ({
            ...prevState,
            isGameOver: false,
            numOfCards: gameDifficulty[difficulty],
        }));
    };

    const onGameOverHandler = () => {
        setGameSettings((prevState) => ({
            ...prevState,
            isGameOver: true,
            listOfPokemons: [],
        }));
    };

    const getScoreboardHandler = async () => {
        if (user.id !== null) {
            const querySnapshot = await getDocs(collection(db, 'scores'));
            const documents = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
            }));
            return documents;
        }
    };

    const onToggleScoreModalHandler = (bool) => {
        setIsScoreModalVisible(bool);
    };

    const signInWithGoogleHandler = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    const onSignOutHandler = () => {
        signOut(auth)
            .then(() => {
                console.log('Sign Out successfully');
            })
            .catch((error) => {
                console.error('Sign out error:', error);
            });
    };

    const onSubmitScoreHandler = async () => {
        try {
            await setDoc(doc(db, 'scores', user.id), {
                username: user.username,
                time: recentScores.time,
                difficulty: recentScores.difficulty,
            }).then(() => {
                setIsScoreModalVisible(false);
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                try {
                    const docSnap = await getDoc(doc(db, 'users', user.uid));
                    if (docSnap.exists()) {
                        setUser((prevState) => ({
                            ...prevState,
                            id: user.uid,
                            username: docSnap.data().username,
                        }));
                        setIsLoginModalVisible(false);
                    } else {
                        let isUnique = false;
                        while (!isUnique) {
                            console.log('Username is unique');
                            const randomName = uniqueNamesGenerator({
                                dictionaries: [adjectives, animals],
                            });
                            const userCollection = collection(db, 'users');
                            const usernameQuery = query(
                                userCollection,
                                where('username', '==', randomName)
                            );
                            const usernameQuerySnapshot = await getDocs(
                                usernameQuery
                            );
                            if (usernameQuerySnapshot.empty) {
                                await setDoc(doc(db, 'users', user.uid), {
                                    username: randomName,
                                });
                                isUnique = true;
                            }
                        }
                    }
                } catch (e) {
                    console.error('Error while fetching user login data:', e);
                }
            } else {
                setUser((prevState) => ({
                    ...prevState,
                    id: null,
                    username: null,
                }));
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <MatchManiaContext.Provider
            value={{
                isLoginModalVisible: isLoginModalVisible,
                isScoreboardVisible: isScoreboardVisible,
                isScoreModalVisible: isScoreModalVisible,
                recentScores: recentScores,
                user: user,
                gameSettings: gameSettings,
                onShowLoginModal: showLoginModalHandler,
                onHideLoginModal: hideLoginModalHandler,
                onShowScoreboard: showScoreboardHandler,
                onHideScoreboard: hideScorebardHandler,
                onGetScoreboard: getScoreboardHandler,
                onSignInWithGoogle: signInWithGoogleHandler,
                onStartGame: onStartGameHandler,
                onGameOver: onGameOverHandler,
                onSignOut: onSignOutHandler,
                onToggleScoreModal: onToggleScoreModalHandler,
                onSetRecentScores: setRecentScores,
                onSubmitScore: onSubmitScoreHandler,
            }}
        >
            {props.children}
        </MatchManiaContext.Provider>
    );
};

export default MatchManiaContext;
