import ReactDOM from 'react-dom';
import Backdrop from '../UI/Backdrop/Backdrop';
import classes from './ScoreModal.module.css';
import Button from '../UI/Button/Button';
import MatchManiaContext from '../../context/MatchManiaContext';
import { useContext } from 'react';

const ModalOverlay = () => {

    const { onToggleScoreModal, recentScores, onSubmitScore, user } = useContext(MatchManiaContext)

    return (
        <div className={classes.modal}>
            <div className={classes.header}>
                <button onClick={() => onToggleScoreModal(false)} className={classes['x-btn-close']}>X</button>
                <h1>Your Score</h1>
            </div>
            <div className={classes.content}>
                <div className={classes.col1}>
                    <p>Time:</p>
                    <p>Difficulty:</p>
                </div>
                <div className={classes.col2}>
                    <p>{recentScores.time}s</p>
                    <p>{recentScores.difficulty}</p>
                </div>
            </div>
            <div className={classes['submit-or-login']}>
                {user.id ? <Button onClick={onSubmitScore} buttonName={'Submit'} /> :
                    <p>You must be logged in to upload your score.</p>
                }
            </div>
        </div>
    );
}

const ScoreModal = () => {
    const { onToggleScoreModal } = useContext(MatchManiaContext)

    return (
        <>
            {ReactDOM.createPortal(<Backdrop onClick={() => onToggleScoreModal(false)} />, document.getElementById('backdrop-root'))}
            {ReactDOM.createPortal(<ModalOverlay />, document.getElementById('overlay-root'))}
        </>
    );
}


export default ScoreModal;