import Button from '../UI/Button/Button';
import classes from './GameSettings.module.css';
import { useState, useContext } from 'react';
import MatchManiaContext from '../../context/MatchManiaContext';

const GameSettings = (props) => {
    const [difficulty, setDifficulty] = useState('easy');

    const { onStartGame } = useContext(MatchManiaContext);

    const handleChangeDifficulty = (event) => {
        setDifficulty(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        onStartGame(difficulty)
    }

    return (
        <form method='POST' onSubmit={handleSubmit} value={difficulty} className={classes['game-settings']}>
            <select onChange={handleChangeDifficulty} value={difficulty} name='difficulty' id='game-difficulty'>
                <option value='easy'>Easy</option>
                <option value='medium'>Medium</option>
                <option value='hard'>Hard</option>
            </select>
            <Button type='submit' buttonName='Start Game' />
        </form>
    );
}

export default GameSettings;