import classes from './Navbar.module.css';
import { useContext } from 'react';
import MatchManiaContext from '../../context/MatchManiaContext';


const Navbar = () => {

    const { onShowLoginModal, onShowScoreboard, user, onSignOut } = useContext(MatchManiaContext);

    return (
        <nav className={classes.navbar}>
            <h1>MatchMania</h1>
            <ul>
                <li onClick={onShowScoreboard}>Scoreboard</li>
                {user.id ? <li onClick={onSignOut}>Log Out</li> : <li onClick={onShowLoginModal}>Log In</li>}
            </ul>
        </nav>
    );
};

export default Navbar;
