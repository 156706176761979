import classes from './Card.module.css';
import { useState, useEffect } from 'react';

const Card = (props) => {
    const [selected, setSelected] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const handleClick = () => {
        if (!selected && !isHidden) {
            props.onCardClick(props.index, props.pokemonId);
            setSelected((prevState) => {
                return !prevState;
            })
        }
    }

    useEffect(() => {
        if (props.selectedCardsIdx.length > 0 && !props.selectedCardsIdx.some(element => element.index === props.index)) {
            setSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedCardsIdx]);

    useEffect(() => {
        if (props.isSame && selected) {
            setIsHidden(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSame]);


    return (
        <div onClick={handleClick} className={`${classes['card-container']} ${selected && classes['card-rotate']} ${isHidden && classes['hide-after-delay']}`}>
            <div className={classes['card-content']}>
                <div className={classes['card-front']}>
                    <h1>{props.pokemonName}</h1>
                    <img src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/dream-world/${props.pokemonId}.svg`} alt='Pokemon name' />
                </div>
                <div className={classes['card-back']}>
                    <div className={classes['pokeball']}></div>
                </div>
            </div>
        </div>
    );
}

export default Card;