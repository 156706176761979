import classes from './CardsList.module.css';
import Card from '../Card/Card';
import { useState, useEffect } from 'react';

const CardsList = (props) => {
    const [selectedCardsIdx, setSelectedCardsIdx] = useState([]);
    const [isSame, setIsSame] = useState(false);
    const [countSame, setCountSame] = useState(0);

    const cardClickHandler = (index, pokemonId) => {
        setSelectedCardsIdx((prevState) => {
            return [...prevState, {
                'index': index,
                'pokemonId': pokemonId,
            }];
        });
        if (selectedCardsIdx.length > 0 && selectedCardsIdx.length % 2 === 0) {
            setSelectedCardsIdx([{
                'index': index,
                'pokemonId': pokemonId,
            }]);
        }
    }

    useEffect(() => {
        if (selectedCardsIdx.length === 2 && selectedCardsIdx[0].pokemonId === selectedCardsIdx[1].pokemonId) {
            setIsSame(true);
            setCountSame((prevState) => { return prevState += 1; });
        }
    }, [selectedCardsIdx])

    //Reset seIsSame
    useEffect(() => {
        if (isSame) {
            setIsSame(false)
            if (countSame === props.numOfCard / 2) {
                props.onGameOver();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSame])

    return (
        <div className={classes['cards-list']}>
            {props.pokemons.map(({ name, url }, index) => (
                <Card onCardClick={cardClickHandler} isSame={isSame} pokemonName={name} pokemonId={url.split('/').at(-2)} url={url} index={index} key={index} selectedCardsIdx={selectedCardsIdx} />
            ))}
        </div>
    );
};

export default CardsList;
