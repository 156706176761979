import { useState, useContext, useEffect } from 'react';
import './App.css';
import CardsList from './components/CardsList/CardsList';
import GameSettings from './components/GameSettings/GameSettings';
import Navbar from './components/Navbar/Navbar';
import LoginModal from './components/LoginModal/LoginModal';
import MatchManiaContext from './context/MatchManiaContext';
import ScoreboardModal from './components/ScoreboardModal/ScoreboardModal';
import ScoreModal from './components/ScoreModal/ScoreModal';
import Loading from './components/UI/Loading/Loading';


function App() {
  const { onStartGame, onGameOver, gameSettings, isLoginModalVisible, isScoreboardVisible, isScoreModalVisible, onToggleScoreModal, onSetRecentScores, recentScores, user } = useContext(MatchManiaContext);
  const [gameContent, setGameContent] = useState(<Loading />)

  const handleGameOver = () => {
    onToggleScoreModal(true);
    onGameOver();
  }

  useEffect(() => {
    if (gameSettings.listOfPokemons.length) {
      setGameContent(<CardsList numOfCard={gameSettings.numOfCards} pokemons={gameSettings.listOfPokemons} onGameOver={handleGameOver} />)
    } else {
      setGameContent(<Loading />)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSettings.listOfPokemons])


  useEffect(() => {
    let intervalId;

    if (gameSettings.listOfPokemons.length > 0) {
      intervalId = setInterval(() => {
        onSetRecentScores((prevState) => ({ ...prevState, time: prevState.time += 1 }));
      }, 1000);
    }


    return () => {
      clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameSettings.listOfPokemons])


  return (
    <>
      {isLoginModalVisible && <LoginModal />}
      {isScoreboardVisible && <ScoreboardModal />}
      {isScoreModalVisible && <ScoreModal />}
      <Navbar />
      <div className='App'>
        <div >
          <h3 className='header'>
            <span>Username: {user.username}</span>
            <span>Time: {recentScores.time}s</span>
            <span>Difficulty: {recentScores.difficulty}</span>
          </h3>
        </div>
        <div className='content'>
          {gameSettings.isGameOver ? <GameSettings onGameStart={onStartGame} /> : gameContent}
          {!gameSettings.isGameOver && <button className='end-game-btn' onClick={onGameOver}>Give Up</button>}
        </div>
      </div>
    </>
  );
}

export default App;
