import classes from './LoginModal.module.css';
import Button from '../UI/Button/Button';
import MatchManiaContext from '../../context/MatchManiaContext';
import { useContext } from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '../UI/Backdrop/Backdrop';
import googleImg from '../../assets/google.png';

const ModalOverlay = () => {

    const { onHideLoginModal, onSignInWithGoogle, user } = useContext(MatchManiaContext)

    const onSubmitHandler = (event) => {
        event.preventDefault()
        onSignInWithGoogle();
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className={classes.modal}>
                <div className={classes.header}>
                    <button onClick={onHideLoginModal} className={classes['x-btn-close']}>X</button>
                    <h1>Log In</h1>
                </div>
                <div className={classes.content}>
                    {!user.id && <Button className={classes['google-btn']} img={googleImg} type='submit' />}
                </div>
            </div>
        </form>
    );
}

const LoginModal = () => {
    const { onHideLoginModal } = useContext(MatchManiaContext)

    return (
        <>
            {ReactDOM.createPortal(<Backdrop onClick={onHideLoginModal} />, document.getElementById('backdrop-root'))}
            {ReactDOM.createPortal(<ModalOverlay />, document.getElementById('overlay-root'))}
        </>
    );
}

export default LoginModal;