import ReactDOM from 'react-dom';
import Backdrop from '../UI/Backdrop/Backdrop';
import classes from './ScoreboardModal.module.css';
import { useContext, useEffect, useState } from 'react';
import MatchManiaContext from '../../context/MatchManiaContext';
import Loading from '../UI/Loading/Loading';

const ModalOverlay = () => {
    const { onGetScoreboard, user } = useContext(MatchManiaContext);
    const [scores, setScores] = useState();

    useEffect(() => {
        if (user.id !== null) {
            onGetScoreboard().then((result) => {
                const sortedScores = [...result];
                sortedScores.sort((a, b) => {
                    const difficultyOrder = { easy: 1, medium: 2, hard: 3 };
                    const difficultyA = difficultyOrder[a.difficulty];
                    const difficultyB = difficultyOrder[b.difficulty];
    
                    if (difficultyA !== difficultyB) {
                        return difficultyB - difficultyA;
                    }
    
                    return a.time - b.time
                })
                setScores(sortedScores);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={classes.modal}>
                <div className={classes.header}>
                    <h1>Scoreboard</h1>
                </div>
                <div className={classes.content}>
                    {scores ? <table className={classes['scoreboard-table']}>
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Username</th>
                                <th>Time</th>
                                <th>Difficulty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {scores.map(({ username, time, difficulty }, idx) => (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{username}</td>
                                    <td>{time}s</td>
                                    <td>{difficulty}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table> : <div className={classes.loading}>
                    {user.id ? <Loading /> : <p> You must be logged in</p>}
                    </div>}
                </div>
            </div>
        </>
    );
};

const ScoreboardModal = () => {
    const { onHideScoreboard } = useContext(MatchManiaContext);

    return (
        <>
            {ReactDOM.createPortal(
                <Backdrop onClick={onHideScoreboard} />,
                document.getElementById('backdrop-root')
            )}
            {ReactDOM.createPortal(
                <ModalOverlay />,
                document.getElementById('overlay-root')
            )}
        </>
    );
};

export default ScoreboardModal;
